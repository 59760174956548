<script setup>
const { isLoading, showWrap, text } = useLoading()
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  size: { type: [Number, String], default: 100 },
})

const $size = computed(() => typeof props.size == 'string' ? props.size : props.size+'px')
</script>

<template>
  <Transition>
    <div v-show="isLoading && showWrap" class="loading">
      <NuxtImg
        src="/logo.png"
        :width="$size"
      />
      <span>{{ text }}</span>
    </div>
  </Transition>
</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>